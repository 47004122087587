
export const dataPick = [
  {
    id: 1,
    price: 1,
    freeMonthPerYear: 2,
    title: 'Online service',
    subTitle: 'Access to multiplayer games'
  },{
    id: 2,
    price: 2,
    isSelect: false,
    freeMonthPerYear: 2,
    title: 'Larger storage',
    subTitle: 'Extra 1TB of cloud save'

  },{
    id: 3,
    price: 2,
    freeMonthPerYear: 2,
    title: 'Customizable profile',
    subTitle: 'Custom theme on your profile'
  }
]