export const dataStep = [
  {
    step: 1,
    title: 'STEP 1',
    subTitle: 'YOUR INFO'
  },{
    step: 2,
    title: 'STEP 2',
    subTitle: 'SELECT PLAN'
  },{
    step: 3,
    title: 'STEP 3',
    subTitle: 'ADD-ONS'
  },{
    step: 4,
    title: 'STEP 4',
    subTitle: 'SUMMARY'
  }
]